import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { StoryblokImageUrlPipe } from './storyblok-image-url.pipe';

@NgModule({
    declarations: [StoryblokImageUrlPipe],
    imports: [CommonModule],
    exports: [StoryblokImageUrlPipe]
})
export class StoryBlokImageUrlModule {
    static forRoot(): ModuleWithProviders<StoryBlokImageUrlModule> {
        return {
            ngModule: StoryBlokImageUrlModule
        };
    }
}
