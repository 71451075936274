import { Pipe, PipeTransform } from '@angular/core';

import { replaceStoryblokCdn } from './storyblok.helper';

export interface UrlConfigOptions {
    width?: number;
    height?: number;
}

@Pipe({
    name: 'storyblokImageUrl',
    standalone: false
})
export class StoryblokImageUrlPipe implements PipeTransform {
    transform(image: string | undefined, config: UrlConfigOptions = { width: 0, height: 0 }): string {
        image = replaceStoryblokCdn(image);
        if (!image) {
            return image ?? '';
        }
        return `${image}/m/${config.width ?? 0}x${config.height ?? 0}`;
    }
}
